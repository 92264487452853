p {
    margin: 0;
}

.header__container {
    text-align: center;
    position: relative;
}

.header__container-top {
    padding-top: 5rem;
    padding-bottom: 1rem;
}

/* ========== CTA ========== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
}

/* ========== SOCIALS ========== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}


/* ========== ME ========== */
.header__container-bottom {
    width: 100%;
    display: flex;
    align-content: center;
    margin: 0 auto;
    margin: auto;
    padding: 0;
}

.me {
    width: 22rem;
    margin: 0 auto;
    background: linear-gradient(var(--color-primary), transparent);
    border-radius: 12rem 12rem 0 0;
    overflow: visible;
    position: relative;
}

.me img {
    height: 60vh;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 0;
}


/* ========== SCROLL DOWN ========== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}



/* =============== MEDIA QUERIES =================*/



@media screen and (max-height: 800px) {

    .header__container-top {
        padding-top: 2rem;
    }

    .me img {
        height: 60vh;
        object-fit: contain;
    }
}

@media screen and (max-height: 600px) {
    .header__container-bottom {
        display: none;
    }

    .header__container-top {
        height: 100vh;
        padding-top: 6rem;
    }
}


.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary));
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    align-content: center;
}



.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 1rem 0 0rem;
    color: var(--color-light);
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =================*/

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}
/* =============== MEDIA QUERIES (SMALL DEVICES) =================*/

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}
