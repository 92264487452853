footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4rem;
}

footer a {
    color: var(--color-bg)
}

.footer__socials a {
    font-size: 2rem;
    margin: 0.5rem;
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 2rem;
}

.footer__copyright {
    margin-bottom: 2rem;
    color: var(--color-bg);
}
/* =============== MEDIA QUERIES (SMALL DEVICES) =================*/

@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }
}
